import libphone, { CountryCode } from 'libphonenumber-js'

interface FormRuleProps{
	check: (v: string, vars?: (string | any)[], form?: any)=>boolean,
	prompt: (l: string, v?: string, vars?: string[])=>string,
}

interface FormRules{
	[x: string]: FormRuleProps;
}

function isCardValid(number: string){
	number = number.replace(/\D/g,'');
	var re = new RegExp("^4");
	if (number.match(re) != null) return true;
	if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) return true;
	re = new RegExp("^3[47]");
	if (number.match(re) != null) return true;
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null) return true;
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null) return true;
	return false;
}

function validatePassword(val: string){
	return (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\.\,\+\*])[a-zA-Z0-9!@#$%^&*\.\,\+\*]{8,32}$/).test(val);
}

const DEFAULT_PROMPT = (l: string, v: string)=>`El valor del campo "${l}" no es válido.`;
export const FORM_RULES : FormRules = {
	empty: {
		check: (v: string)=>{
			return !!v && !(v === undefined || v.length==0);
		},
		prompt: (l: string)=>`Favor de ingresar el valor del campo "${l}"`,
	},
	checked: {
		check: (v: string)=>{
			return !!v && v=='on';
		},
		prompt: (l: string)=>`Favor de seleccionar el checkbox "${l}"`,
	},
	email: {
		check: (v: string)=>{
			return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi).test(v);
		},
		prompt: (l: string)=>`El campo "${l}" no es un correo electrónico válido.`,
	},
	range: {
		check: (v: string, vars: string[]=[])=>{
			var n = parseFloat(v);
			var min = parseFloat(vars[0]);
			var max = parseFloat(vars[1]);
			return n>=min && n<=max;
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor de campo "${l}" debe de ser entre ${vars[0]} y ${vars[1]}`,
	},
	min: {
		check: (v: string, vars: string[]=[])=>{
			var n = parseFloat(v);
			var min = parseFloat(vars[0]);
			return n>=min;
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor del campo "${l}" debe de ser mínimo ${vars[0]}`,
	},
	max: {
		check: (v: string, vars: string[]=[])=>{
			var n = parseFloat(v);
			var max = parseFloat(vars[0]);
			return n<=max;
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor del campo "${l}" debe de ser máximo ${vars[0]}`,
	},
	minLength: {
		check: (v: string, vars: any[]=[])=>{
			return !!v && v.length>=vars[0];
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor del campo "${l}" debe de ser de mínimo ${vars[0]} caracteres.`,
	},
	maxLength: {
		check: (v: string, vars: any[]=[])=>{
			return !v || v.length<=vars[0];
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor del campo "${l}" debe de ser de máximo ${vars[0]} caracteres.`,
	},
	length: {
		check: (v: string, vars?: any[])=>{
			if(!vars) return true;
			if(vars.length>=1){
				if(!v || v.length<parseInt(vars[0])) return false;
			}
			if(vars.length>=2){
				if(!v || v.length>parseInt(vars[1])) return false;
			}
			return !!v;
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor de "${l}" debe de ser ${vars.length>=2 ? 'entre' : 'de'} ${vars[0]}${vars.length>=2 ? (' y '+vars[1]) : ''} caracteres`
	},
	number: {
		check: (v: string)=>{
			if(typeof v==='string'){
				return (/^[0-9]+$/).test(v);
			}else{
				return !Number.isNaN(parseInt(v))
			}
		},
		prompt: (l: string)=>`El valor del campo "${l}" debe de ser un número.`,
	},
	float: {
		check: (v: string)=>{
			if(typeof v==='string'){
				return (/^([0-9]+)(\.[0-9]+)?$/).test(v);
			}else{
				return !Number.isNaN(parseFloat(v));
			}
		},
		prompt: (l: string)=>`El valor del campo "${l}" debe de ser un número decimal.`,
	},
	enum: {
		check: (v: string, vars: string[]=[])=>{
			return !!v && vars.indexOf(v)!=-1;
		},
		prompt: (l: string, v?: string, vars: string[]=[])=>`El valor del campo "${l}" debe de ser de uno de los siguientes valores: ${vars.join(', ')}`
	},
	url: {
		check: (v: string)=>{
			return !!v && (/(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/i).test(v);
		},
		prompt: (l: string)=>`El valor del campo "${l}" debe de ser un URL.`
	},
	equals: {
		check: (v: string, vars?: string[])=>{
			if(!vars) return false;
			return v===vars[0];
		},
		prompt: (l: string)=>`El valor del campo "${l}" no concuerda.`
	},
	notEquals: {
		check: (v: string, vars?: string[])=>{
			if(!vars) return true;
			return v!=vars[0];
		},
		prompt: (l: string)=>`El valor del campo "${l}" no es válido.`
	},
	password: {
		check: (v: string)=>{
			return validatePassword(v);
		},
		prompt: (l: string)=>`La contraseña debe de ser de minimo 8 caracteres y debe de tener por lo menos: 1 número, 1 letra mayúscula, 1 letra minúscula y 1 caracter especial.`
	},
	creditcard: {
		check: (v: string)=>{
			return isCardValid(v);
		},
		prompt: (l: string)=>`El número de tarjeta no es válido.`,
	},
	phone: {
		check: (v: string, p?: string[])=>{
			var ph = libphone(v, (p && p.length>=1) ? p[0] as CountryCode : 'MX');
			return !!ph && ph.isValid();
		},
		prompt: (l: string)=>`El número de teléfono no es válido`,
	},
	match: {
		check: (v: string, p?: string[], form?: any)=>{
			if(!form) return false;
			return form[p[0]]===v;
		},
		prompt: (l: string)=>`El valor del campo "${l}" no concuerda`,
	}
}

function validateInput(name: string, val: any, rules: Rule[], label?: string, form?: any){
	var prompts: string[] = [], final_result: boolean = true;
	for(var r of rules){
		var result: boolean = true;
		if(('if' in r) && !r.if) continue;
		if(r.skipEmpty===true && (!val || val.toString().length==0)) continue;
		if(r.rule instanceof RegExp){
			result = new RegExp((r.rule as RegExp)).test(val);
		}else if(typeof r.rule==='function'){
			result = ((r.rule as any) as ((v: string)=>boolean))(val);
		}else{
			var fn = FORM_RULES[r.rule as string];
			if(!fn || !fn.check) continue;
			result = fn.check(val, r.params, form);
			if(!r.prompt) r.prompt = fn.prompt(label || r.label || name, val, r.params);
		}
		if(!result){
			if(!r.prompt) r.prompt = DEFAULT_PROMPT(label || r.label || name, val);
			final_result = false;
			prompts.push(r.prompt);
		}
	}
	return { valid: final_result, prompts };
}

export type RuleTypeEnum = 'empty' | 'checked' | 'email' | 'range' | 'min' | 'max' | 'minLength' | 'maxLength' | 'number' | 'float' | 'enum' | 'url' | 'equals' | 'notEquals' | 'length' | 'password' | 'creditcard' | 'phone' | 'match';
type PreInputRuleType = RuleTypeEnum | RegExp | ((val: string|any)=>boolean) | Rule;

interface Rule{
	prompt?: string,
	rule: PreInputRuleType,
	skipEmpty?: boolean,
	if?: boolean,
	params?: any[],
	label?: string,
}

interface InputRule{
	label: string,
	rules: PreInputRuleType[]
}

interface Form{
	[x: string]: any
}
export interface FormErrors{
	[x: string]: boolean
}

export type RuleType = InputRule | PreInputRuleType[];

export type ValidatorFormRules<T=any> = { [x in keyof T]?: RuleType }

var ruleTypeToRule = (rule: RuleType) : Rule[]=>{
	var input_rules = rule;
	var new_rules : Rule[] = []
	if('label' in rule){
		input_rules = (rule as InputRule).rules;
	}

	for(var r of (input_rules as PreInputRuleType[])){
		if(typeof r==='string'){
			new_rules.push({ prompt: null, rule: r as RuleTypeEnum });
		}else if('rule' in r){
			new_rules.push(r);
		}
	}

	return new_rules;
}

function Validator<T extends Form>(form: T, rules: ValidatorFormRules){
	var errors : FormErrors = {}, prompts: string[] = [], error_count = 0;
	for(var i in form){
		errors[i] = false;
	}

	for(var k in rules){
		if(!rules[k] || (rules[k] as Rule[]).length==0) continue;
		var val = form[k];
		var validate_rules : Rule[] = [];
		var label : string = null;

		if('label' in rules[k]){
			label = (rules[k] as InputRule).label;
			validate_rules = ruleTypeToRule((rules[k] as InputRule).rules);
		}else{
			validate_rules.push(...ruleTypeToRule(rules[k]));
		}

		if(!validate_rules || validate_rules.length==0) continue;
		var pr = validateInput(k, val, validate_rules, label, form);
		error_count += pr.prompts.length;
		if(!pr.valid) errors[k] = true;
		prompts.push(...pr.prompts);
	}

	return {
		valid: error_count<=0,
		errors,
		form: form as { [x in keyof T]: any },
		prompts,
	};
}

Validator.validate = (rules: RuleType, value: any, form?: any) : boolean=>{
	var validation = validateInput('', value, ruleTypeToRule(rules), null, form);
	return validation.valid;
}

export default Validator;