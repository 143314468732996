import axios from 'axios';
import { CreateForm, USER_AUTH_EVENT, USER_AUTH_KEY, UserLogin } from './AuthClasses';
import { APIResponse } from '@tempu/components/Classes';

export const ROOT_URL = process.env.REACT_APP_API_URL;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_ROOT = process.env.REACT_APP_CDN_ROOT;
export const DEBUG = process.env.REACT_APP_DEBUG=='1';
export const CAPTCHA_ACTIVE = process.env.REACT_APP_CAPTCHA_ACTIVE=='1';
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;
export const APPLE_CLIENT = process.env.REACT_APP_APPLE_CLIENT;

async function post<T=any>(endpoint: string, data?: any) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'Content-Type': 'application/json'
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/auth/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
}

var loginRefresh = async (refresh: string)=>{
	return post<UserLogin>('refresh', { refresh });
}

var login = async (email: string, password: string, captcha: string, remember: boolean=false)=>{
	var res = await post<UserLogin>('login', { email, password, remember, captcha });
	if(!res.error){
		window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(res.data));
		setTimeout(()=>{
			window.dispatchEvent(new Event(USER_AUTH_EVENT));
		}, 50);
	}
	return res;
}

var createUser = async (data: CreateForm, captcha: string)=>{
	return post<{ email: string }>('create', {
		...data,
		captcha
	});
}

var loginApple = async (apple_auth: string)=>{
	return post<UserLogin>('login/apple', { apple_auth });
}

var loginGoogle = async (google_auth: string)=>{
	return post<UserLogin>('login/google', { google_auth });
}

export default {
	createUser,
	login,
	loginApple,
	loginGoogle,
	loginRefresh,
}