import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import Validator, { ValidatorFormRules } from './Validator';
import { bindFormChange } from './Util';

const useValidator = <T,>(initValues: T, rules?: ValidatorFormRules<T>)=>{
	var [data, setData] = useState<T>(initValues);
	var [prompts, setPrompts] = useState<string[]>(null);
	var [errors, setErrors] = useState<{
		[x in keyof T]: boolean
	}>({} as any);

	useEffect(()=>{
		if(data && typeof data==='object' && Object.keys(errors).length===0){
			var err : any = {};
			for(var i in data){
				err[i] = false;
			}
			setErrors(err);
			reset()
		}
	}, []);

	var reset = ()=>{
		if(initValues && typeof initValues==='object'){
			var dt : any = {};
			for(var v in initValues){
				dt[v] = initValues[v];
			}
			setData(dt);
		}
	}

	var validate = (data_override?: any, rules_override?: ValidatorFormRules<T>) : { valid: boolean, prompts: string[], errors: typeof errors }=>{
		if(!rules) return { valid: true, prompts: [], errors };
		// console.log(data);
		// console.log({ ...data, ...data_override }, { ...rules, ...rules_override });
		var vld = Validator({ ...data, ...(data_override || {}) }, { ...rules, ...rules_override });

		var nw_errors : any = {};
		for(var i in data){
			nw_errors[i] = vld.errors[i];
		}
		setErrors(nw_errors);
		setPrompts(vld.prompts);
		return {
			valid: vld.valid,
			prompts,
			errors: nw_errors
		}
	}

	var onDataChange = (name: keyof T, semantic: boolean=false, target: boolean=false, update_error: boolean=true)=>{
		if(errors[name] && rules && rules[name] && update_error){
			var valid = Validator.validate(rules[name], data[name], data);
			if(valid){
				setErrors({
					...errors,
					[name]: !valid
				});
			}
		}

		return bindFormChange(data, setData)(name, semantic, target);
	}

	var setDataValue = (name: keyof T, value: any)=>{
		return setData({
			...data,
			[name]: value
		});
	}

	return {
		data,
		errors,
		onDataChange,
		prompts,
		reset,
		setPrompts,
		setData: setDataValue,
		validate,
	}
}

export {
	useValidator,
}