import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { USER_AUTH_EVENT, USER_AUTH_KEY, UserLogin } from './AuthClasses';
import { APIResponse } from '@tempu/components/Classes';
import API from './AuthAPI';
import moment from 'moment';


var AuthContext = createContext<{
	user: UserLogin,
	user_saved: boolean,
	loginSaved: ()=>Promise<APIResponse<UserLogin>>,
	clearSaved: ()=>void,
}>({
	user: null,
	user_saved: null,
	loginSaved: ()=>null,
	clearSaved: ()=>null,
});
var useAuth = () => useContext(AuthContext);
var AuthProvider = (props: PropsWithChildren)=>{
	var [user, setUser] = useState<UserLogin>(null);

	useEffect(()=>{
		if(user===null){
			onLogin()
		}
		window.addEventListener(USER_AUTH_EVENT, onLogin);
		return ()=>{
			window.removeEventListener(USER_AUTH_EVENT, onLogin);
		}
	}, [user]);

	var onLogin = ()=>{
		var login = window.localStorage.getItem(USER_AUTH_KEY);
		try{
			var login_data = JSON.parse(login) as UserLogin;
			var now = moment().unix();
			setUser(login_data);
			if(login_data.tokens.refresh_expires<=(now+30)){
				window.localStorage.removeItem(USER_AUTH_KEY);
			}
		}catch(e){}
	}
	
	var loginSaved = ()=>{
		if(!user || !user.tokens) return Promise.resolve({
			error: true,
			code: 'LCL-EXTSV-1',
			message: 'No existe una autenticación válida (LCL-EXTSV-1)'
		})
		return API.loginRefresh(user.tokens.refresh);
	}

	var clearSaved = ()=>{
		window.localStorage.removeItem(USER_AUTH_KEY);
		setUser(null);
	}

	var now = moment().unix();
	return <AuthContext.Provider value={{
		user,
		user_saved: user && user.tokens?.refresh && user.tokens.refresh_expires>(now+30),
		loginSaved,
		clearSaved,
	}}>
		{props.children}
	</AuthContext.Provider>
}

export {
	AuthProvider,
	useAuth,
}