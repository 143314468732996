import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthLogin from './AuthLogin';
import { AuthProvider } from './AuthHooks';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<AuthProvider>
			<AuthLogin />
		</AuthProvider>
	</React.StrictMode>
);